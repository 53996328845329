<template>
  <div>
    <HistoryFilter
      v-if="$can('index', 'module_payment.daily_closing.daily_boxes')"
      :loading="loading"
      @searchFilter="searchFilter($event, true)"
      @filterPage="filterPage"
      :filtersMounted="filtersMounted"
    />
    <b-card>
      <TitleTable titleTable="History" iconTable="GitBranchIcon" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
      :items="items"
      :fields="fields"
      hover
      bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
        >
      
      <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="expandedRows[row.index]"
            class="vs-checkbox-con"
            @change="onChangeRow(row)"
          >
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-card v-for="(cashItem, index) in row.item.cashes" :key="index">
            <CardCash :isHistory="true" :CashStats="cashItem">
              <slot>
                <b-col>
                  <div
                    class="h-100 d-flex flex-wrap align-items-center justify-content-around card-global"
                  >
                    <b-avatar
                      variant="light"
                      size="70"
                      class="shadow mb-2 text-center"
                    >
                      <feather-icon
                        size="28"
                        icon="RepeatIcon"
                        class="text-primary"
                      />
                    </b-avatar>
                    <div>
                      <h3 class="text-white">Transactions</h3>
                      <div class="d-flex align-items-start">
                        <h3 class="text-white">
                          {{ cashItem.total_transactions }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div
                    class="h-100 d-flex flex-wrap align-items-center justify-content-around card-global"
                  >
                    <b-avatar
                      variant="light"
                      size="70"
                      class="shadow mb-2 text-center"
                    >
                      <feather-icon
                        size="28"
                        icon="ActivityIcon"
                        class="text-primary"
                      />
                    </b-avatar>
                    <div>
                      <h3 class="text-white">Frontdesk</h3>
                      <h4 class="text-white" style="max-width: 6vw">
                        {{ cashItem.frontdesks.name }}
                      </h4>
                    </div>
                  </div>
                </b-col>
              </slot>
            </CardCash>
            <b-table
              small
              bordered
              responsive="sm"
              class="mt-2"
              show-empty
              empty-text="No matching records found"
              :fields="fieldsCash"
              :items="cashItem.movements"
            >
              <template #cell(name)="data">
                <b-link
                  :to="{
                    name: 'recipe-patient',
                    params: { recipeId: data.item.appointment },
                  }"
                  >{{ data.item.patient_name }}</b-link
                >
              </template>
              <template #cell(value)="data">
                <b-badge pill :variant="getColor(data.item.movementType)">
                  <feather-icon
                    :icon="getIcon(data.item.movementType)"
                    class="mr-25"
                  />
                  {{ "$ " + data.item.total }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="flat-primary"
                  class="btn-icon btn-sm"
                  v-b-tooltip.hover.top="'Swicth movement'"
                  @click="switchMovement(data.item.id)"
                  :disabled="loading"
                >
                  <feather-icon icon="RepeatIcon" size="16" />
                </b-button>
              </template>
            </b-table>
          </b-card>
        </template>
        <template #cell(facilities)="">
          <b-media vertical-align="center" v-if="facilityId">
            <template #aside>
              <b-avatar size="32" :src="facilityId.avatar" />
            </template>
            <b-link
              :to="{
                name: 'admin-facilities-view',
                params: { id: facilityId.id },
              }"
            >
              <span
                class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"
              >
                <span>
                  {{ facilityId.name }}
                </span>
                <span>
                  {{ facilityId.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(cash_statuses)="data">
          <b-badge
            pill
            :variant="data.item.cash_statuses_id === 1 ? 'success' : 'danger'"
          >
            {{ data.item.cash_statuses.name }}
          </b-badge>
        </template>
        <template #cell(frontdesks)="data">
          <b-media vertical-align="center" v-if="data.item.frontdesks">
            <template #aside>
              <b-avatar size="32" :src="data.item.frontdesks.avatar" />
            </template>
            <b-link
              :to="{
                name: 'admin-frontdesks-view',
                params: { id: data.item.frontdesks.id },
              }"
            >
              <span
                class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"
              >
                <span>
                  {{ data.item.frontdesks.name }}
                </span>
                <span>
                  {{ data.item.frontdesks.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(closing_statuses)="data">
          <div v-if="data.item.closing_statuses.length > 0" class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.closing_statuses[0].name
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'See report'"
            @click="seeReport(data.item)"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  BMedia,
  BLink,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";

import axiosCV from "@/services/cash-account/index";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import HistoryFilter from "./HistoryFilter.vue";
import CardCash from "../CardCash";
import moment from "moment";
import { notificationCheck } from "@/mixins/NotificationSetup";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BMedia,
    BLink,
    BFormCheckbox,
    HistoryFilter,
    CardCash,

    ToastNotification,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: "show_details" },
        { key: "facility_name", label: "Facility", sortable: true },
        { key: "comments", label: "Comments" },
        { key: "frontdesk_statement", label: "Cash Box" },
        { key: "date", label: "Date", sortable: true },
        { key: "cash", label: "Cash" },
        { key: "cc", label: "Card" },
        { key: "expenses", label: "Expenses" },
        { key: "total" },
        { key: "closingStatus", label: "Closing Status" },
        { key: "actions" },
      ],
      items: null,
      loading: false,
      fieldsCash: [
        { key: "name", label: "Name" },
        { key: "detail", label: "Detail" },
        {
          key: "cash",
          label: "Cash",
        },
        {
          key: "cc",
          label: "Card",
        },
        {
          key: "convenience_fee",
          label: "Convenience Fee",
        },
        // { key: "value", label: "Movement" },
        { key: "value", label: "Movement", sortable: true },
        { key: "transaction_id", label: "transaction ID" },
        { key: "actions", label: "Actions" },
      ],
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      itemFilter: null,
      facilityId: null,
      filtersMounted: {
        facilities_ids: null,
        start_date: null,
        end_date: null,
      },
      expandedRows: {},
    };
  },
  mounted() {
    this.filtersMounted = {
      start_date: moment().format("MM-DD-YYYY 00:00:00"),
      end_date: moment().format("MM-DD-YYYY 23:59:00"),
    };
    if (this.$route.params.id) {
      this.filtersMounted.facilities_ids = [Number(this.$route.params.id)];
    }
    this.getGlobalHistory();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getGlobalHistory();
      } else {
        this.searchFilter(this.itemFilter);
      }
    },
  },
  methods: {
    seeReport(box) {
      axiosCV.downloadReport(box.id).then((res) => {
        const blob = new Blob([res]);
        const filename = `global-cash-report-${new Date().toLocaleString()}.pdf`;

        const urlExcel = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlExcel;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(urlExcel);
        document.body.removeChild(a);
      });
    },
    getGlobalHistory() {
      if (this.$route.params.id) {
        axiosCV
          .cashGlobalHistory(this.perPage, this.filtersMounted)
          .then(
            ({ registro: { data, total, current_page, per_page, ...res } }) => {
              this.items = data;
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
            }
          );
      }
    },
    pageChanged(page) {
      const dataFilter = this.$route.params.id
        ? {
            facilities_ids: [this.$route.params.id],
          }
        : this.itemFilter;
      axiosCV
        .cashGlobalHistoryPagination(this.perPage, page, dataFilter)
        .then(
          ({ registro: { data, total, current_page, per_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          }
        );
    },
    searchFilter(value, clearCollapse = false) {
      if(clearCollapse){
        this.expandedRows = {}
      }
      if (this.filtro) {
        this.loading = true;
        this.itemFilter = value;
        axiosCV
          .cashGlobalHistory(this.perPage, this.itemFilter)
          .then(
            ({ registro: { data, total, current_page, per_page, ...res } }) => {
              this.items = data.map((item, index) => {
                return {
                  ...item,
                  _showDetails: this.expandedRows[index]
                }
              })
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = res.from;
              this.dataMeta.to = res.to;
              this.currentPage.page = current_page;
              this.loading = false;
            }
          );
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    getColor(id) {
      if (id === "Income") return "success";
      if (id === "Egress") return "danger";
      if (id === "Not charge") return "secondary";
      return "success";
    },
    getIcon(id) {
      if (id === "Income") return "ArrowUpIcon";
      if (id === "Egress") return "ArrowDownIcon";
      if (id === "Not charge") return "MinusIcon";
      return "ArrowDownIcon";
    },
    onChangeRow(row){
      row.toggleDetails()
      this.expandedRows[row.index] = !row.detailsShowing
    },
    switchMovement(idMovement){
      this.loading = true
      const data = {
        "movement_id": idMovement
      }
      axiosCV
          .cashGlobalDailySwitch(data)
          .then(res => {
            const { type, message } = notificationCheck(res); 
                this.$refs.toast[type](message);
                
                setTimeout(() => {
                  this.searchFilter(this.itemFilter)
                  this.loading = false
                }, 2000);
          });
    }
  },
};
</script>

<style></style>
