import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export const fromConfig = ({
  isStatic = false,
  mode = false,
  wrap = false,
  noCalendar = false,
  dateFormat = "m-d-Y H:i",
  time_24hr = true,
  allowInput = true,
  enableTime = true,
  defaultHour = true,
  confirmDate = false
}) => {
  const plugins = confirmDate ? [new confirmDatePlugin({})] : []
  let config = {
    static: isStatic,
    noCalendar,
    dateFormat,
    time_24hr,
    allowInput,
    enableTime,
    wrap,
    plugins
  };
  if (defaultHour)
    config = {
      ...config,
      ...defaultHours(true),
    };

  if (mode) config.mode = mode;
  return config;
};

export const untilConfig = ({
  isStatic = false,
  mode = false,
  noCalendar = false,
  dateFormat = "m-d-Y H:i",
  time_24hr = true,
  allowInput = true,
  enableTime = true,
  defaultHour = true,
  wrap = false,
}) => {
  let config = {
    static: isStatic,
    noCalendar,
    dateFormat,
    time_24hr,
    allowInput,
    enableTime,
    wrap,
  };

  if (defaultHour)
    config = {
      ...config,
      ...defaultHours(false),
    };

  if (mode) config.mode = mode;

  return config;
};

function defaultHours(from) {
  if (from)
    return {
      defaultHour: 0,
    };

  return {
    defaultHour: 23,
    defaultMinute: 59,
    defaultSecconds: 59,
  };
}
