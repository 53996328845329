import { userAxios } from '../index'

const facilityList = async (perPage = 50) => {
    try {
        return await userAxios.get(`facility/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityCreate = async (data) => {
    try {
        return await userAxios.post('facility', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityUpdate = async (id, data) => {
    try {
        return await userAxios.put(`facility/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityDetail = async (id) => {
    try {
        return await userAxios.get(`facility/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`facility/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityDelete = async (id) => {
    try {
        return await userAxios.delete(`facility/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`facility/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`facility/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const FacilityTypes = async () => {
    try {
        return await userAxios.get(`facility_types`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityPartners = async (id) => {
    try {
        return await userAxios.get(`partners/facilities/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    facilityList,
    facilityCreate,
    facilityUpdate,
    facilityDetail,
    facilityDelete,
    facilityPagination,
    facilityFilter,
    facilityFilterPagination,
    FacilityTypes,
    facilityPartners
}