<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Facility</label>
          <v-select
          ref="select"
            v-model="filters.facilities_ids"
            class="w-100"
            :options="facilityOptions"
            :reduce="(facility) => facility.pivot.facility_id"
            multiple
            label="name"
          >
            <template #list-header>
              <li class="vs__dropdown-option vs__dropdown-option--highlight" v-on:click="selectAll">
                 {{ isSelectAllOption ? 'Deselect All' : 'Select All'}}
              </li>
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Start Date") }}</label>
          <flat-pickr
            v-model="filters.start_date"
            :config="fConfig"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.End Date") }}</label>
          <flat-pickr
            v-model="filters.end_date"
            :config="uConfig"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Closing Status</label>
          <v-select
            v-model="filters.closing_status_id"
            class="w-100"
            :options="ClosingOptions"
            :reduce="(value) => value.id"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Frontdesk global cash</label>
          <v-select
            v-model="filters.frontdesk_global_cash_id"
            class="w-100"
            :options="frontdeskOptions"
            :reduce="(value) => value.id"
            label="name"
            @search="searchUsers"
            @keyup.enter="searchFilter"
          >
            <template slot="option" slot-scope="option">
              <div class="d-flex">
                <b-avatar size="27" variant="light" :src="option.avatar" />
                <span class="ml-1">
                  {{ option.name }} {{ option.lastname }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                <b-avatar size="27" variant="light" :src="option.avatar" />
                <span class="ml-1">
                  {{ option.name }} {{ option.lastname }}</span
                >
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Frontdesk unit cash</label>
          <v-select
            v-model="filters.frontdesk_unit_cash_id"
            class="w-100"
            :options="frontdeskOptionsUnit"
            :reduce="(value) => value.id"
            label="name"
            @search="searchUnitCashUser"
            @keyup.enter="searchFilter"
          >
            <template slot="option" slot-scope="option">
              <div class="d-flex">
                <b-avatar size="27" variant="light" :src="option.avatar" />
                <span class="ml-1">
                  {{ option.name }} {{ option.lastname }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                <b-avatar size="27" variant="light" :src="option.avatar" />
                <span class="ml-1">
                  {{ option.name }} {{ option.lastname }}</span
                >
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import SpinnerLoading from "@/components/SpinnerLoading";

import axiosUser from "@/services/admin/user";
import axiosFacility from "@/services/admin/facilities";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BAvatar,
    vSelect,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    filtersMounted: {
      type: Object,
      default: null,
      required: false,
    },
  },
  computed: {
    isSelectAllOption(){
      return this.filters.facilities_ids.length === this.facilityOptions.length 
    }
  },
  data() {
    return {
      stateOptions: [],
      filters: {
        facilities_ids: [],
        start_date: null,
        end_date: null,
        closing_status_id: null,
        frontdesk_global_cash_id: null,
        frontdesk_unit_cash_id: null,
      },
      ClosingOptions: [
        { name: "Correctly", id: 1 },
        { name: "Inconsistencies", id: 2 },
      ],
      cashStatusOptions: [
        { name: "open", id: 1 },
        { name: "closed", id: 2 },
        { name: "audited", id: 3 },
        { name: "pre-closure", id: 4 },
      ],
      frontdeskOptions: [],
      frontdeskOptionsUnit: [],
      clearTimeoutBuscador: "",
      fConfig: fromConfig({ dateFormat: "m-d-Y H:i:S" }),
      uConfig: untilConfig({ dateFormat: "m-d-Y H:i:S" }),
      facilityOptions: [],
      userId: JSON.parse(localStorage.getItem("userData")).id,
    };
  },
  watch: {
    filtersMounted: {
      handler(newValue) {
        const { facilities_ids, end_date, start_date } = this.filtersMounted;

        this.filters.end_date = end_date;
        this.filters.start_date = start_date;

        if (facilities_ids) {
          this.filters.facilities_ids = facilities_ids;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getFrontdesk();
    this.getFacilityOptions();
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    getFrontdesk() {
      const data = {
        role: ["FRONT-DESK", "ADMIN", "SUPER-ADMIN"],
      };
      axiosUser.userFilter(10, data).then(({ registro: { data } }) => {
        this.frontdeskOptions = data;
        this.frontdeskOptionsUnit = data;
      });
    },
    getFacilityOptions() {
      axiosFacility.facilityPartners(this.userId).then(({ registro }) => {
        this.facilityOptions = registro;
      });
    },
    searchUsers(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: "FRONT-DESK",
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosUser.userFilter(10, datos).then(({ registro: { data } }) => {
            this.frontdeskOptions = data;
            loading(false);
          });
        }, 350);
      }
    },
    searchUnitCashUser(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["FRONT-DESK", "ADMIN", "SUPER-ADMIN"],
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosUser.userFilter(10, datos).then(({ registro: { data } }) => {
            this.frontdeskOptionsUnit = data;
            loading(false);
          });
        }, 350);
      }
    },
    selectAll() {
      const select = this.$refs.select;
      if(this.isSelectAllOption){
        this.filters.facilities_ids = []
      }else{
        const arrayFacility = []
        select.options.forEach(option => {
          arrayFacility.push(Number(option.pivot.facility_id))
        });
  
        this.filters.facilities_ids = arrayFacility
      }
      
      // bug caused by onAfterSelect
      select.open = false
    }
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vs__dropdown-option{
  cursor: pointer;
}
</style>
